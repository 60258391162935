.patient-progress-overview {
    /* Styling for the overall container */
    border: 1px solid #ccc;
    padding: 20px;
    margin-left: 20px; /* Space between this and the patient list */
    flex-grow: 1; /* This will make the progress overview take up the rest of the horizontal space */
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split into two columns of equal width */
    gap: 20px; /* Add some space between columns */
    margin: 20px; /* Or adjust as needed */
}

/* You might have additional styles for headings and other elements */
.patient-progress-overview h2 {
    color: #333;
    margin-bottom: 20px;
}

  /* Add responsive styles or other layout-related styles as needed */

.container {
    display: flex; /* This enables flexbox */
    height: 100vh; /* This makes sure your container fills the vertical height of the viewport */
}

.patient-list {
    flex: 0 0 auto; /* This means that your patient list will not grow or shrink */
    width: 300px; /* This sets a fixed width for the patient list, you can adjust as necessary */
    overflow-y: auto; /* This adds a scrollbar to the patient list if it overflows */
}

.recovery-plan ul {
    padding-left: 20px; /* Adjust the padding to align the list properly */
}

