.exercise-difficulty-feedback {
    text-align: center; /* Center align the feedback section */
}

.feedback-stars {
    display: flex;
    justify-content: center; /* Center the stars */
    align-items: center;
}

.daily-feedback {
    display: flex;
    flex-direction: column; /* Stack star and day indicator vertically */
    align-items: center;
    margin: 0 5px; /* Give some space between each day's feedback */
}

.star {
    font-size: 50px; /* Increase font size for larger stars */
    margin-bottom: 5px; /* Space between star and day indicator */
}

  /* Style for the day indicators */
.day-indicator {
    font-size: 14px; /* Adjust as needed */
    margin-top: -5px; /* Negative margin to move closer to the star */
}

.red {
    color: #FF0000; /* Red for hard */
}

.yellow {
    color: #FFD700; /* Yellow for medium */
}

.green {
    color: #008000; /* Green for easy */
}

.title {
    /* Styles for the title at the top of the component */
    margin-bottom: 20px; /* Provide some spacing below the title */
    background-color: #c2f5c4;
    padding: 15px;
    font-size: 30px
}
