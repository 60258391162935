.dashboard-header {
    background-color: #c2f5c4; /* Your chosen color */
    padding: 10px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust as per design */
}

.business-logo {
    height: 120px; /* Adjust as needed */
    width: auto; /* Adjust as needed */
    /* Rest of the logo styling */
}

.business-name {
    /* Style for your business name */
    /* Rest of the name styling */
    font-size: 45px;
}

.navigation-buttons {
    display: flex;
    justify-content: center; /* Centers the buttons */
    padding: 10px 0; /* Adds padding above and below the buttons */
}

.navigation-buttons button {
    margin: 0 70px; /* Increases spacing between buttons */
    background-color: #6ab36d; /* Sets the button background to green */
    border: none; /* Removes the border */
    color: white; /* Sets the text color to white */
    padding: 10px 15px; /* Adds some padding inside the buttons */
    border-radius: 10px; /* Optional: Rounds the corners of the buttons */
    cursor: pointer; /* Changes the mouse cursor on hover */
    font-size: 1em; /* Adjust the font size as needed */
}

.navigation-buttons button:hover {
    background-color: #367c39; /* Darker green on hover for visual feedback */
}
  /* ... rest of your CSS ... */ 