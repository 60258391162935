.activity-time {
    /* Your styles for the activity time container */
}

.pie-chart-with-legend {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align for neat look */
}

.pie-chart-placeholder {
    width: 200px;
    height: 200px;
    background: conic-gradient(
        red 0% 25%, 
        yellow 25% 50%, 
        rgb(29, 224, 29) 50% 75%
    ); /* Adjust this to match your actual pie chart colors */
    border-radius: 50%;
    /* More styles as necessary */
}

.chart-legend {
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Space above the legend */
}

.legend-item {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Space between legend items */
}

.legend-color-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px; /* Space between color box and text */
}

.legend-color-box.missed {
    background-color: red;
}

.legend-color-box.incomplete {
    background-color: yellow;
}

.legend-color-box.completed {
    background-color: green;
}

