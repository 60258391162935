.patient-list-container {
    display: flex;
    /* Adjust your styles here */
  }
  
  .patient-list {
    overflow-y: auto;
    padding: 10px;
    max-height: calc(100vh - 50px - 20px);
    /* Other styles */
  }
  
  .patient-item {
    margin-bottom: 10px; /* Adjust the value as needed */
  /* Additional styling for the items */
    padding: 10px; /* Adjust the value as needed for internal spacing */
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    cursor: pointer; /* To indicate the item is clickable */
    /* Other styles */
  }
  
  .patient-item.selected {
    background-color: #e0e0e0; /* Highlight selected patient */
    /* Other styles */
  }
  
  /* Continue with your styles for .patient-progress-overview */