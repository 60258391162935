.recovery-plan {
    /* Styles for the recovery plan component */
}

.plan-title {
    /* Styles for the title at the top of the component */
    margin-bottom: 20px; /* Provide some spacing below the title */
    background-color: #c2f5c4;
    padding: 15px;
    font-size: 30px
}

.plan-content {
    display: flex;
}

.buttons-container {
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-right: 20px; /* Adjust the spacing between buttons and exercises list */
}

.plan-button {
    background-color: #b6d7a8; /* A shade of green, match this to your design */
    border: none;
    border-radius: 20px; /* Rounded corners for the buttons */
    padding: 10px;
    margin-bottom: 10px; /* Space between buttons */
    cursor: pointer;
    outline: none;
    text-align: center;
    width: 140px; /* Fixed width for buttons */
    font-size: 17px;
}

.exercises-list {
    list-style-type: none; /* No bullets */
    padding: 0; /* No padding */
    margin: 0; /* No margin */
    font-size: 20px;
    align-items: center;
    
}

.exercises-list li {
    padding-left: 1em; /* Indent text */
    text-indent: -1em; /* Align bullets */
}

